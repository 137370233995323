import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The Good`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`The background updates depending on the weather outside`}</li>
          <li parentName="ul">{`The clock updated every second`}</li>
          <li parentName="ul">{`To do and Notes list fully functional`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`The Bad`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Used Bulma.io but it wasn't really necessary`}</li>
          <li parentName="ul">{`The weather status should be updated from time to time`}</li>
          <li parentName="ul">{`The weather bit is not fully included in Vue as it relies on external functions -  they should be added to the component`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`The Ugly`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`On a small screen, a few elements look wonky`}</li>
          <li parentName="ul">{`The Notes List area moves slightly when the To Do list is open/closed`}</li>
          <li parentName="ul">{`Used mostly ES6 syntax - not compatible with some browsers`}</li>
          <li parentName="ul">{`The API key for openweathermap shouldn't be hardcoded on the `}<inlineCode parentName="li">{`main.js`}</inlineCode>{` file`}</li>
        </ul>
      </li>
    </ul>
    <h1 {...{
      "id": "explanation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#explanation",
        "aria-label": "explanation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Explanation`}</h1>
    <p>{`The idea of creating a Landing page that shows quotes and the current weather came to me when doing the challenges on freecodecamp. Two of the javascript challenges were to create two separated apps - one a weather station, one for quotes.`}</p>
    <p>{`I thought it could be cool to have a page that shows both. So I spent a few hours brainstorming about the landing page and what sort of things I would like to add to it. A To Do List, a Notes List and a clock seemed like a good thing to add.`}</p>
    <p>{`With the idea in my head, I wanted a guide that I could follow while creating the app. I sketched the basic idea on a piece of paper and then opened up `}<a parentName="p" {...{
        "href": "https://designer.io"
      }}>{`GravitDesigner`}</a>{` and designed the landing page.`}</p>
    <p>{`Since I wanted to learn VueJS I took the opportunity to use it and create a project. I had issues with using geolocation and Vue together, so as a workaround I wrote basic javascript code to call the `}<a parentName="p" {...{
        "href": "https://openweathermap.org"
      }}>{`OpenWeatherMap API`}</a>{`.`}</p>
    <p>{`One thing I wanted to do was to change the background of the page depending on the weather outside. A quick function was created so that it updates the page background depending on the name of the weather icon passed.`}</p>
    <p>{`Once the page was showing the current weather, I jumped to the quotes. The hardest part was to try and find an API that generated quotes - after a long, long search I ended up using `}<a parentName="p" {...{
        "href": "https://favqs.com"
      }}>{`FavQuotes Api`}</a>{`.`}</p>
    <p>{`Working on the To Do List created some issues - I thought you could create different VueJS apps before reading about components, so I had to do some refactoring and working around with the quotes app and integrate the to-do list.  It was a quite fun thing to do.`}</p>
    <p>{`How to create a notes app kind of puzzled me, but in the end, I figured that it could be nothing more than an extended version of the To Do List so hack away I went and managed to make it work after struggling with a few bits.`}</p>
    <h1 {...{
      "id": "few-things-im-happy-with",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#few-things-im-happy-with",
        "aria-label": "few things im happy with permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Few things I'm happy with`}</h1>
    <ul>
      <li parentName="ul">{`How I used GravitDesigner to mock the landing page and use it while coding the page`}</li>
      <li parentName="ul">{`How I used VueJs to hide/unhide elements of the lists`}</li>
      <li parentName="ul">{`How I learned about things I needed to change in the app by observing my fiancé and mom using the landing page`}</li>
      <li parentName="ul">{`How I implemented a sort of Scrum board with trello to implement this project - it was good practice`}</li>
    </ul>
    <h1 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h1>
    <p>{`GitHub repo: `}<a parentName="p" {...{
        "href": "https://github.com/FabioRosado/landing-page/"
      }}>{`https://github.com/FabioRosado/landing-page/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      